(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name entitat.informacio.constant:actionButton
   *
   * @description
   *
   */
  angular
  .module('neo.components')
  .value('actionButton', {
    dial: true,
    buttonImage: 'images/ic_more_vert_white_48px.svg',
    tooltip: 'Opcions',
    execute: function () {
    },
    actions: []
  });
}());
